import { Props as ImageProps } from './components/Image/Image'

const EXPLORER_API_URL =
    'https://amoeba-explorer-vs6o6hcumq-uc.a.run.app/v1/explore-items'
const BUCKET_URL = 'https://storage.googleapis.com/amoeba-bucket-prod/'

const imageFolder =
    'https://storage.googleapis.com/amoeba-bucket-prod/lykdat_assets'
const tryItImageFolder = imageFolder + '/try_it'
// @todo: use HERO_IMAGE_SRC everywhere
const HERO_IMAGE_SRC = tryItImageFolder + '/img10.jpeg'
const PLACEHOLDER_IMAGE =
    // tslint:disable-next-line
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN88/r1fwAJUAPDBN5V9QAAAABJRU5ErkJggg=='

const supportedLanguages = new Set(['en', 'fr', 'nl'])

const FEEDBACK_LINK = 'https://forms.gle/1zRBWi4F6N2QwUfFA'

const CONTACT_LINK = 'mailto:contact@lykdat.com'
const DEMO_IMAGES = [
    'https://storage.googleapis.com/amoeba-bucket-prod/cdn.shopify.com/1592996448883157/888_Gold_3709_JK.jpg',
    'https://storage.googleapis.com/projectameoba.appspot.com/84b1d6b6490e2fc77033c118d677877a_image-2020-12-26-182244.jpeg',
    'https://storage.googleapis.com/projectameoba.appspot.com/f18d9c8f9672cef654e5e40cc2d5168b_image-2021-01-23-001112.jpeg',
    'https://storage.googleapis.com/amoeba-bucket-prod/images.theurge.com/1561559153548909/r-pencil-skirt-1.jpg',
    'https://storage.googleapis.com/amoeba-bucket-prod/images2.productserve.com/1593711359626208/es2.productserve.com',
    'https://storage.googleapis.com/amoeba-bucket-prod/images2.productserve.com/1588466948725493/es2.productserve.com',
    'https://storage.googleapis.com/amoeba-bucket-prod/images2.productserve.com/1608752241479358/es2.productserve.com',
    tryItImageFolder + '/img40.jpeg',
    tryItImageFolder + '/img1.jpeg',
    tryItImageFolder + '/img3.jpeg',
    tryItImageFolder + '/img4.jpeg',
    tryItImageFolder + '/img5.jpeg',
    // tryItImageFolder+'/img6.jpeg',
    tryItImageFolder + '/img7.jpeg',
    tryItImageFolder + '/img8.jpeg',
    tryItImageFolder + '/img9.jpeg',
    tryItImageFolder + '/img10.jpeg',
    tryItImageFolder + '/img11.jpeg',
    tryItImageFolder + '/img12.jpeg',
    tryItImageFolder + '/img13.jpeg',
    // tryItImageFolder+'/img14.jpeg',
    // tryItImageFolder+'/img15.jpeg',
    // tryItImageFolder+'/img16.jpeg',
    tryItImageFolder + '/img17.jpeg',
    tryItImageFolder + '/img18.jpeg',
    tryItImageFolder + '/img19.jpeg',
    tryItImageFolder + '/img20.jpeg',
    tryItImageFolder + '/img21.jpeg',
    tryItImageFolder + '/img22.jpeg',
    tryItImageFolder + '/img23.jpeg',
    tryItImageFolder + '/img24.jpeg',
    tryItImageFolder + '/img25.jpeg',
    tryItImageFolder + '/img26.jpeg',
    tryItImageFolder + '/img27.jpeg',
    tryItImageFolder + '/img28.jpeg',
    tryItImageFolder + '/img29.jpeg',
    tryItImageFolder + '/img30.jpeg',
    tryItImageFolder + '/img31.jpeg',
    tryItImageFolder + '/img32.png',
    tryItImageFolder + '/img33.png',
    tryItImageFolder + '/img34.jpeg',
    tryItImageFolder + '/img35.jpeg',
    tryItImageFolder + '/img36.jpeg',
    tryItImageFolder + '/img37.jpeg',
    tryItImageFolder + '/img38.jpeg'
]

const LOADER_IMAGES = DEMO_IMAGES

const B2B_ASSET_PATH =
    'https://storage.googleapis.com/amoeba-bucket-prod/b2b_landing_page_images/'

const CC_TO_CURR = {
    HU: 'HUF',
    CZ: 'CZK',
    AU: 'AUD',
    RO: 'RON',
    ID: 'IDR',
    IN: 'INR',
    BR: 'BRL',
    RU: 'RUB',
    HR: 'HRK',
    JP: 'JPY',
    MY: 'MYR',
    KR: 'KRW',
    IL: 'ILS',
    MX: 'MXN',
    ZA: 'ZAR',
    NG: 'NGN',
    NZ: 'NZD',
    NO: 'NOK',
    CN: 'CNY',
    TR: 'TRY',
    BG: 'BGN',
    PL: 'PLN',
    SG: 'SGD',
    CH: 'CHF',
    TH: 'THB',
    SE: 'SEK',
    CA: 'CAD',
    GB: 'GBP',
    HK: 'HKD',
    IS: 'ISK',
    PH: 'PHP',
    DK: 'DKK'
}

const EUROZONE = [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES'
]

const categoryCoversFolder = '/sub-cat-images'

const CATEGORY_COVERS = (path = categoryCoversFolder) => {
    return {
        dress: {
            copy: 'DRESSES',
            image: categoryCoversFolder + '/' + path + '/dress.jpeg'
        },
        tops: {
            copy: 'TOPS',
            image: categoryCoversFolder + '/' + path + '/tops.jpeg'
        },
        skirt: {
            copy: 'SKIRTS',
            image: categoryCoversFolder + '/' + path + '/skirt.jpeg'
        },
        trousers: {
            copy: 'TROUSERS',
            image: categoryCoversFolder + '/' + path + '/trousers.jpeg'
        },
        outerwear: {
            copy: 'JACKETS',
            image: categoryCoversFolder + '/' + path + '/outerwear.jpeg'
        },
        sportswear: {
            copy: 'SPORTSWEAR',
            image: categoryCoversFolder + '/' + path + '/sportswear.jpeg'
        },
        shorts: {
            copy: 'SHORTS',
            image: categoryCoversFolder + '/' + path + '/shorts.jpeg'
        },
        suits: {
            copy: 'SUITS',
            image: categoryCoversFolder + '/' + path + '/suits.jpeg'
        },
        footwear: {
            copy: 'SHOES',
            image: categoryCoversFolder + '/' + path + '/footwear.jpeg'
        },
        swimwear: {
            copy: 'SWIMWEAR',
            image: categoryCoversFolder + '/' + path + '/swimwear.jpeg'
        },
        bags: {
            copy: 'BAGS',
            image: categoryCoversFolder + '/' + path + '/bags.jpeg'
        }
    }
}

const vendorLogosFolder = imageFolder + '/vendor_logos'
const VENDOR_LOGOS = {
    asos: vendorLogosFolder + '/asos.png',
    boohoo: vendorLogosFolder + '/boohoo.png',
    farfetch: vendorLogosFolder + '/farfetch.png',
    fashionnova: vendorLogosFolder + '/fashionnova.png',
    'na-kd': vendorLogosFolder + '/na-kd.png',
    nastygal: vendorLogosFolder + '/nastygal.png',
    prettylittlething: vendorLogosFolder + '/prettylittlething.png',
    shein: vendorLogosFolder + '/shein.png',
    shopstyle: vendorLogosFolder + '/shopstyle.png',
    spartoo: vendorLogosFolder + '/spartoo.png',
    yesstyle: vendorLogosFolder + '/yesstyle.png',
    zalando: vendorLogosFolder + '/zalando.png',
}

const HERO_DIR = 'hero-images'
const SEARCH_IMAGES_DIR: string = 'image-search-images'

const firstPhoto: ImageProps = {
    srcSet: `/${SEARCH_IMAGES_DIR}/boots-small.jpg 277w, /${SEARCH_IMAGES_DIR}/boots-medium.jpg 554w, /${SEARCH_IMAGES_DIR}/boots-large.jpg 831w`,
    src: `boots-small.jpg`,
    alt: '',
    sizes: '(max-width: 480px) 50vw, (max-width: 800px) 25vw, 200px'
}
const secondPhoto: ImageProps = {
    srcSet: `/${SEARCH_IMAGES_DIR}/scarf-small.jpg 277w, /${SEARCH_IMAGES_DIR}/scarf-medium.jpg 554w, /${SEARCH_IMAGES_DIR}/scarf-large.jpg 831w`,
    src: `scarf-small.jpg`,
    alt: '',
    sizes: '(max-width: 480px) 50vw, (max-width: 800px) 25vw, 200px'
}
const thirdPhoto: ImageProps = {
    srcSet: `/${SEARCH_IMAGES_DIR}/lady-small.jpg 260w, /${SEARCH_IMAGES_DIR}/lady-medium.jpg 520w, /${SEARCH_IMAGES_DIR}/lady-large.jpg 780w`,
    src: `lady-small.jpg`,
    alt: '',
    sizes: '(max-width: 480px) 50vw, (max-width: 800px) 25vw, 300px'
}

const SEARCH_IMAGES = { firstPhoto, secondPhoto, thirdPhoto }
const BRANDS_DIR: string = 'supported-brands-logos'
const BRANDS = [
    'forever21',
    'plt',
    'boohoo',
    'asos',
    'fashionnova',
    'farfetch',
    'tedbaker',
    'shein',
    'fashion24',
    'shopstyle',
    'nike'
]
const OUR_VIDEO =
    'https://storage.googleapis.com/amoeba-bucket-prod/lykdat_assets/about_us/text-search-demo.MP4'

export {
    OUR_VIDEO,
    BRANDS,
    BRANDS_DIR,
    HERO_IMAGE_SRC,
    HERO_DIR,
    SEARCH_IMAGES,
    PLACEHOLDER_IMAGE,
    supportedLanguages,
    FEEDBACK_LINK,
    CONTACT_LINK,
    BUCKET_URL,
    DEMO_IMAGES,
    LOADER_IMAGES,
    CC_TO_CURR,
    EUROZONE,
    EXPLORER_API_URL,
    B2B_ASSET_PATH,
    VENDOR_LOGOS,
    CATEGORY_COVERS
}
