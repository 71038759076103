import React from 'react'
import BackgroundImgRemover from '../../components/BackgroundImgRemover'

const BgRemover: React.FC = () => {
    return (
        <>
            <BackgroundImgRemover />
        </>
    )
}

export default BgRemover
