import React from 'react'
import HomeTopSection from '../../components/HomeTopSection'

const Home: React.FC = () => {
    return (
        <>
            <HomeTopSection />
        </>
    )
}

export default Home
