import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import AppContext from '../../helpers/AppContext'
import Image from '../Image'
import TextLine from '../TextLine'
import { VENDOR_LOGOS } from '../../constants'
import theme from '../../theming'
import { StyleResponse } from '../../helpers/bacillusClient'

export interface Props {
    className?: string
    styles: StyleResponse
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    ${theme.media.from.md`
        grid-template-columns: repeat(4, minmax(0, 1fr));
    `};
    column-gap: ${theme.space.medium};
    row-gap: ${theme.space.medium};
    width: 100%;
    margin-bottom: ${theme.space.largest};
    padding-bottom: ${theme.space.large};
`

const StyleCard = styled(Link)`
    border-radius: ${theme.size.small};
    border: 1px solid ${theme.color.grayLighter};
    height: 190px;
    ${theme.media.from.md`
        height: 310px;
    `}
    padding: ${theme.space.small};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.color.white};
`

const SectionDiv = styled.div`
    padding-top: ${theme.space.medium};
    ${theme.media.from.md`
        padding-top: ${theme.space.smallest};
    `}
`

const StyleList: React.FC<Props> = (props) => {
    const { className, styles } = props

    const suggestions: string[] = []
    styles.groups.forEach((group) => {
        suggestions.push(...group.styles.map((i) => i.image))
    })

    console.log(suggestions, 'suggestions')

    const content = suggestions.map((v) => (
        <StyleCard key={v} to="">
            <Image src={v} fit="contain" height="512" setCors={false} />
        </StyleCard>
    ))

    return (
        <SectionDiv>
            <TextLine text="Suggested Styles" type="heading4" />
            <Wrapper>{content}</Wrapper>
        </SectionDiv>
    )
}

export default StyleList
