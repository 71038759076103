import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslate } from '../../helpers/useTranslate'
import theme from '../../theming'
import Button, { PrimaryCta } from '../Button'
import UserAccountTrigger from '../UserAccountTrigger'
import ReactGA from 'react-ga'
import * as AboutPage from '../About'
import AppContext from '../../helpers/AppContext'
import ImagePicker from '../ImagePicker'
import Image from '../Image'
import TextLine from '../TextLine'
import { detectColors, detectItems, getStyles, StyleResponse } from '../../helpers/bacillusClient'
import axios from 'axios'
import StyleList from './StyleList'

export interface Props {
    className?: string
    primary?: boolean
}

async function srcToBlob (src: string) {
    const response = await axios({
        method: 'get',
        url: src,
        responseType: 'blob'
    })

    return response.data
}

const HomeTopSection: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [loadingDetection, setLoadingDetection] = useState(false)
    const [loadingStyles, setLoadingStyles] = useState(false)
    const [imgSrc, setImgSrc] = useState<string>()
    const [gender, setGender] = useState<string>('Women')
    const [colors, setColors] = useState<string[]>([])
    const [items, setItems] = useState<string[]>([])
    const [styles, setStyles] = useState<StyleResponse>()

    useEffect(() => {
        if (!imgSrc) {
            return
        }

        ctx.openDrawer(getImgViewer(imgSrc))
    }, [imgSrc, loadingDetection, gender, loadingStyles])

    const getImgViewer = (src: string) => {
        let viewerFooter = (<TextLine text="Analysing Image ..." type='subtitle' center={true} />)
        if (loadingStyles) {
            viewerFooter = (
                <>
                    <TextLine
                        text="Fetching Styles ..."
                        type="subtitle"
                        center={true}
                    />
                    <TextLine
                        text="It takes about 50 seconds."
                        type="body"
                        center={true}
                    />
                </>
            )
        } else if (!loadingDetection) {
            viewerFooter = (
                <>
                    <div style={{margin: '10px 0'}}>
                        <TextLine text="select the Gender to style for" type='subtitle' center={false} />
                    </div>
                    <div>
                        {['Men', 'Women'].map((c) => (
                            <span style={{margin: '4px'}}>
                                <Button
                                    textType="small"
                                    key={c}
                                    text={c}
                                    padding={theme.space.smaller}
                                    color="black"
                                    onClick={() => setGender(c)}
                                    inline={true}
                                    transparent={c != gender}
                                />
                            </span>
                        ))}
                    </div>
                    <div style={{margin: '10px 0'}}>
                        <TextLine text="Detected Items" type='subtitle' center={false} />
                    </div>
                    <div>
                        {items.map((c) => (
                            <span style={{margin: '4px'}}>
                                <TextLine text={c} type='body' center={false} inline={true} />
                                {/* <Button
                                    textType="small"
                                    key={0}
                                    text={c}
                                    padding={theme.space.smaller}
                                    color="black"
                                    onClick={() => console.log('selected streetwear')}
                                    inline={true}
                                    transparent={true}
                                /> */}
                            </span>
                        ))}
                    </div>
                    {/* <div style={{margin: '10px 0'}}>
                        <TextLine text="select the color of the item to style" type='subtitle' center={false} />
                    </div>
                    <div>
                        {colors.map((c) => (
                            <span style={{margin: '2px'}}>
                                <Button
                                    textType="small"
                                    key={0}
                                    text={c}
                                    padding={theme.space.smaller}
                                    color="black"
                                    onClick={() => console.log('selected streetwear')}
                                    inline={true}
                                    transparent={true}
                                />
                            </span>
                        ))}
                    </div> */}
                    <div style={{marginTop: '10px'}}>
                        <PrimaryCta icon="Check" full={true} onClick={getSuggestions} />
                    </div>
                </>
            )
        }

        return (
            <div>
                <div style={{ height: '60vh' }}>
                    <Image
                        className="expandedQueryImage"
                        height="100%"
                        width="100%"
                        src={src}
                        alt="query image"
                        fit="contain"
                        setCors={false}
                    />
                </div>
                {viewerFooter}
            </div>
        )
    }

    const getSuggestions = async () => {
        if (!imgSrc) {
            return
        }

        setLoadingStyles(true)
        const imgBlob = await srcToBlob(imgSrc)
        const realGender = gender == 'Men' ? 'male' : 'female'
        const resp = await getStyles(imgBlob, realGender)
        setLoadingStyles(false)
        setStyles(resp)
        ctx.openDrawer(null)
    }

    const onImageAdded = async (src: string) => {
        setLoadingDetection(true)
        setImgSrc(src)

        const imgBlob = await srcToBlob(src)

        const dItems = await detectItems(imgBlob)
        // const dColors = await detectColors(imgBlob)

        // setColors(dColors.map((i) => i.name))
        // remove duplicates
        const deduplicated = new Set(dItems.map((i) => i.name))
        setItems(Array.from(deduplicated))
        setLoadingDetection(false)
    }

    return (
        <div className={className}>
            <div className="container">
                <div className="display">
                    <h1 className={'hero-text'}>
                        <AboutPage.MarkedText>
                            <span>Generate</span>
                        </AboutPage.MarkedText>
                        <span>Style Inspirations </span>
                        Out of
                        <AboutPage.MarkedText>
                            <span>Nowhere</span>
                        </AboutPage.MarkedText>
                    </h1>
                </div>
                <ImagePicker onImageAdded={onImageAdded} />
            </div>
            {styles ? <StyleList styles={styles}/> : null}
        </div>
    )
}

const StyledHomeTopSection = styled(HomeTopSection)((props) => {
    return {
        // backgroundColor: 'white',
        marginTop: -16, // reset global padding-top
        ['.container']: {
            margin: 'auto',
            padding: `${theme.space.large}`,
            maxWidth: 700,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 0,
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `${theme.space.largest} 0`
            },
            ['.display']: { marginBottom: theme.space.larger },
            ['.hero-text']: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 30,
                fontWeight: 700,
                lineHeight: '29px',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    lineHeight: '58px',
                    fontSize: 48
                }
            }
        }
    }
})
export default StyledHomeTopSection
