import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import Button, { PrimaryCta } from '../Button'
import * as AboutPage from '../About'
import AppContext from '../../helpers/AppContext'
import ImagePicker from '../ImagePicker'
import Image from '../Image'
import TextLine from '../TextLine'
import { removeImageBackground, ImageTranscodeResponse } from '../../helpers/bacillusClient'
import axios from 'axios'
import ImageList from './ImageList'

export interface Props {
    className?: string
    primary?: boolean
}

async function srcToBlob (src: string) {
    const response = await axios({
        method: 'get',
        url: src,
        responseType: 'blob'
    })

    return response.data
}

const BackgroundImgRemover: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [removingBg, setRemovingBg] = useState(false)
    const [imgSrc, setImgSrc] = useState<string>()
    const [transcoded, setTranscoded] = useState<ImageTranscodeResponse>()

    useEffect(() => {
        if (!imgSrc) {
            return
        }

        ctx.openDrawer(getImgViewer(imgSrc))
    }, [imgSrc, removingBg])

    const getImgViewer = (src: string) => {
        let viewerFooter = (
            <div style={{marginTop: '10px'}}>
                <PrimaryCta icon="Check" full={true} onClick={removeBg} />
            </div>
        )
        if (removingBg) {
            viewerFooter = (
                <>
                    <TextLine
                        text="Removing Background ..."
                        type="subtitle"
                        center={true}
                    />
                </>
            )
        }

        return (
            <div>
                <div style={{ height: '60vh' }}>
                    <Image
                        className="expandedQueryImage"
                        height="100%"
                        width="100%"
                        src={src}
                        alt="query image"
                        fit="contain"
                        setCors={false}
                    />
                </div>
                {viewerFooter}
            </div>
        )
    }

    const removeBg = async () => {
        if (!imgSrc) {
            return
        }

        setRemovingBg(true)
        const imgBlob = await srcToBlob(imgSrc)
        const resp = await removeImageBackground(imgBlob)
        setRemovingBg(false)
        setTranscoded(resp)
        ctx.openDrawer(null)
    }

    const onImageAdded = async (src: string) => {
        setImgSrc(src)
    }

    return (
        <div className={className}>
            <div className="container">
                <div className="display">
                    <h1 className={'hero-text'}>
                        <AboutPage.MarkedText>
                            <span>Remove</span>
                        </AboutPage.MarkedText>
                        <span>Apparel Image </span>
                        <AboutPage.MarkedText>
                            <span>Backgrounds</span>
                        </AboutPage.MarkedText>
                    </h1>
                </div>
                <ImagePicker onImageAdded={onImageAdded} />
            </div>
            {transcoded ? (
                <>
                    <ImageList transcodedImage={transcoded} />
                    <div>
                        <br />
                        <TextLine text="Image URL" type="body" bold />
                        <br />
                        <a href={transcoded.image} target="_blank">
                            <TextLine text={transcoded.image} type="body" color="grey" />
                        </a>
                        <br />
                    </div>
                </>
            ) : null}
        </div>
    )
}

const StyledBackgroundImgRemover = styled(BackgroundImgRemover)((props) => {
    return {
        // backgroundColor: 'white',
        marginTop: -16, // reset global padding-top
        ['.container']: {
            margin: 'auto',
            padding: `${theme.space.large}`,
            maxWidth: 700,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 0,
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `${theme.space.largest} 0`
            },
            ['.display']: { marginBottom: theme.space.larger },
            ['.hero-text']: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 30,
                fontWeight: 700,
                lineHeight: '29px',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    lineHeight: '58px',
                    fontSize: 48
                }
            }
        }
    }
})
export default StyledBackgroundImgRemover
