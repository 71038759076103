import React, {
    MutableRefObject,
    SyntheticEvent,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import TiktokPixel from 'tiktok-pixel'
import { search, trackMerchandiserEvent } from '../../helpers/apiClient'
import AppContext from '../../helpers/AppContext'
import { convertAndFormat, priceToNumber } from '../../helpers/currency'
import { useTranslate } from '../../helpers/useTranslate'
import { isSaveButtonUsed, markSaveButtonasUsed } from '../../helpers/utils'
import theme from '../../theming'
import Button from '../Button'
import Icon from '../Icon'
import Image from '../Image'
import TextLine from '../TextLine'
import UserAccountTrigger from '../UserAccountTrigger'
import UserProfile from '../UserProfile'
import WithAutoToolTip from '../WithAutoTooltip'
import { OutOfStockBtn } from './OutOfStockBtn'

export interface Props extends RouteComponentProps {
    className?: string
    product: Product
    similarityBadgeText?: string
    exploreTracker?: () => void
    onClick?: () => void
    disableTracking?: boolean
}

const getProductImage = (product: Product): string => {
    if (product.matching_image) {
        return product.matching_image
    }

    return product.images[0]
}

const mobileHeight = '230px'
const desktopHeight = '300px'

const ProductCard: React.FC<Props> = (props) => {
    const { product, similarityBadgeText, exploreTracker, onClick } = props
    const ctx = useContext(AppContext)
    const cardRef: MutableRefObject<HTMLImageElement | null> = useRef(null)
    const { translate } = useTranslate()
    const productImage = getProductImage(product)

    const [isBrokenImage, setIsBrokenImage] = useState(false)
    const [liked, setLiked] = useState(false)
    const [shouldShowSavedItemsTip, setShouldShowSavedItemsTip] = useState(
        false
    )

    useEffect(() => {
        ctx.storage
            .getLikedProducts(ctx)
            .then((likedProducts) => {
                const isLiked =
                    likedProducts.findIndex((p) => p.id === product.id) > -1
                setLiked(isLiked)
            })
            .catch((e) => {
                console.log(e)
            })
    }, [setLiked, product, ctx.currentUser, ctx.storage])

    useEffect(() => {
        if (!cardRef.current || cardRef.current.previousElementSibling) {
            return
        }
        setShouldShowSavedItemsTip(true)
    }, [cardRef, setShouldShowSavedItemsTip])
    let productUrl = product.url
    if (product.is_affiliate && product.affiliate_url) {
        productUrl = product.affiliate_url
    }

    const isAsos = product.vendor.trim().toLowerCase() === 'asos'

    const onProductClick = (e: SyntheticEvent) => {
        e.preventDefault()

        ReactGA.event({
            category: 'User',
            action: 'Clicked on product'
        })
        TiktokPixel.track('ClickButton', {
            content_type: 'product',
            content_id: product.url,
            description: `clicked on product (${product.vendor})`
        })
        const merchEvent: BMEvent = {
            payload: product,
            eventType: 'click',
            targetType: 'product',
            targetId: product.id,
            timestamp: new Date().toISOString()
        }
        if (!props.disableTracking) {
            trackMerchandiserEvent(merchEvent)
        }
        ReactGA.event({
            category: 'User',
            action: `Clicked on product (${product.vendor})`
        })
        if (exploreTracker) {
            exploreTracker()
        }
        if (onClick) {
            onClick()
        }
        ctx.storage.saveRecentProduct(product, ctx)
        window.open(productUrl, '_blank')
    }

    const toggleProduct = (e: SyntheticEvent, forStockOut?: boolean) => {
        e.stopPropagation()
        if (!liked) {
            const merchEvent: BMEvent = {
                payload: product,
                eventType: 'save',
                targetType: 'product',
                targetId: product.id,
                timestamp: new Date().toISOString()
            }

            trackMerchandiserEvent(merchEvent)
        }
        if (!ctx.currentUser) {
            const defaultGaAction = 'Tried to save while signed out'
            const stockOutGaAction = 'Opted to be notified while signed out'
            const gaAction = forStockOut ? stockOutGaAction : defaultGaAction
            ReactGA.event({
                category: 'User',
                action: gaAction
            })
            ctx.openDrawer(
                <UserProfile prompt="Please sign in to save items you like" />
            )
            return
        }

        setLiked((prevSaved) => {
            markSaveButtonasUsed()
            prevSaved
                ? ctx.storage.unsaveLikedProduct(product, ctx)
                : ctx.storage.saveLikedProduct(product, ctx)
            return !prevSaved
        })
    }

    const formatPrice = (_price: string, currency: UserCurrency) => {
        return convertAndFormat(_price, currency, ctx)
    }

    const seeMorLikeThis = (e: SyntheticEvent) => {
        ReactGA.event({
            category: 'User',
            action: `Clicked 'see more like this'`
        })
        search({ imageURL: productImage }, ctx)
            .then((resultURL) => {
                props.history.push('/empty')
                props.history.replace(resultURL)
            })
            .catch((err) => {
                // report? log?
            })
        e.stopPropagation()
    }

    const getFallbackProductImage = (): string => {
        if (product.vendor_images.length) {
            return product.vendor_images[0]
        }

        return ''
    }

    const brokenImageCallback = () => {
        if (!isBrokenImage) {
            setIsBrokenImage(true)
        }
    }

    if (isBrokenImage) {
        return null
    }

    const vendorClass = `contentRow contentRowVendor ${
        isAsos ? 'asosVendorLabel' : ''
    }`

    const similarityBadge = similarityBadgeText ? (
        <div className="similarityBadge badge">
            <TextLine inline={true} text={similarityBadgeText} type="small" />
        </div>
    ) : null

    const getPriceDom = () => {
        if (
            product.reduced_price &&
            priceToNumber(product.reduced_price) > 0 &&
            priceToNumber(product.reduced_price) < priceToNumber(product.price)
        ) {
            const reducedPrice = formatPrice(
                product.reduced_price,
                product.currency
            )
            return (
                <div>
                    <TextLine
                        inline={true}
                        text={formatPrice(product.price, product.currency)}
                        color="gray"
                        type="small"
                        strike={true}
                        className="originalPrice"
                    />
                    <TextLine
                        inline={true}
                        text={reducedPrice}
                        color="black"
                        bold={true}
                    />
                </div>
            )
        } else {
            let priceString = `${
                priceToNumber(product.price) ? product.price : ''
            }`
            priceString =
                priceString || `${priceToNumber(product.reduced_price || '0')}`
            const formattedPrice = formatPrice(priceString, product.currency)
            return (
                <div>
                    <TextLine
                        inline={true}
                        text={formattedPrice}
                        type="small"
                        bold={true}
                        color="black"
                    />
                </div>
            )
        }
    }

    const discountInfoLabel =
        ctx.promos &&
        ctx.promos[product.vendor] &&
        ctx.promos[product.vendor].regions.includes(product.country || '') ? (
            <div className="discountBadge badge">
                <TextLine
                    type="small"
                    text={`Sale ${ctx.promos[product.vendor].percentage}%`}
                    inline={true}
                    color="white"
                />
            </div>
        ) : null

    // check for undefined because it may not be set at all
    const outOfStockBadge =
        product.in_stock !== undefined && !product.in_stock ? (
            <OutOfStockBtn
                outOfStockText={translate('OUT_OF_STOCK')}
                CTA={translate('GET_NOTIFIED_CTA')}
                onClick={(e) => toggleProduct(e, true)}
            />
        ) : null

    const getDeliveryInfo = () => {
        const deliveryDom = (
            <div className="contentRow">
                <div>
                    <Icon
                        name="Truck"
                        sizebyScale="small"
                        className="deliveryIcon"
                    />
                    <TextLine
                        text="Delivers to you"
                        type="bodysmallest"
                        inline={true}
                        color="gray"
                    />
                </div>
            </div>
        )

        return product.delivers_to_location ? deliveryDom : null
    }

    const tipText = `
        <div>
            <div>Save items and we will let you </div>
            <div>know when they go on sale. </div> 
            <div 
                class="close-tooltip" 
                onclick="((e)=>{
                    document.querySelector('#saveTip1').style = 'none';
                    localStorage.setItem('used-save-button', 'true')
                })()"
            >Got it </div>
        </div>`

    const uuid = 'saveItem-tooltip'
    let saveButton = (
        <Button
            round={true}
            iconOnly={true}
            background="white"
            color="black"
            icon={liked ? 'MdFavorite' : 'MdFavoriteBorder'}
            onClick={toggleProduct}
            sizeByScale="medium"
            padding={theme.space.smallest}
            className="saveButton"
        />
    )

    const moreLikeThisText = translate('PRODUCTCARD_SEE_MORE_LIKE_THIS')

    if (!ctx.currentUser) {
        saveButton = <UserAccountTrigger>{saveButton}</UserAccountTrigger>
    }

    if (shouldShowSavedItemsTip) {
        saveButton = (
            <div className="saveButtonContainer">
                <WithAutoToolTip
                    top={true}
                    condition={() => !isSaveButtonUsed()}
                    content={tipText}
                    uuid={uuid}
                    id="saveTip1"
                    showAfter={1000}
                    hideAfter={10000}
                    position={{ top: 0, left: 11 }}
                    html={true}
                    background="#FFF0CA"
                    border="#FFD771"
                    textColor="#000000"
                >
                    {saveButton}
                </WithAutoToolTip>
            </div>
        )
    }
    return (
        <div
            data-id={product.id}
            data-score={product.score}
            className={props.className}
            onClick={onProductClick}
            ref={cardRef}
        >
            <div className="wrapper">
                <div className="image-wrapper">
                    <a href={productUrl} target="_blank" className="image-link">
                        <Image
                            src={productImage}
                            altSrc={getFallbackProductImage()}
                            height={
                                ctx.isDesktop ? desktopHeight : mobileHeight
                            }
                            width="100%"
                            fit="cover"
                            className="image"
                            failureCallback={brokenImageCallback}
                            setCors={false}
                        />
                    </a>
                    <div className="labels">
                        <div>
                            {discountInfoLabel}
                            {similarityBadge}
                            {outOfStockBadge}
                        </div>
                        {saveButton}
                    </div>
                </div>
                <div>
                    <div className="content">
                        <div className="contentRowGroup">
                            <div className="contentRow">
                                <TextLine
                                    text={product.name}
                                    color="black"
                                    oneLiner={true}
                                    type="small"
                                />
                            </div>
                            <div className="contentRow">{getPriceDom()}</div>
                        </div>
                        <div className="contentRowGroup">
                            <div className={vendorClass}>
                                <div>
                                    <TextLine
                                        text={translate('SOLD_BY')}
                                        type="bodysmallest"
                                        color="gray"
                                        inline={true}
                                    />
                                    {'\u00A0'}
                                    <TextLine
                                        text={product.vendor}
                                        className="vendorLabel"
                                        type="bodysmallest"
                                        inline={true}
                                    />
                                </div>
                            </div>
                            {getDeliveryInfo()}
                        </div>

                        <div className="contentRow">
                            <div onClick={seeMorLikeThis}>
                                <TextLine
                                    type="bodysmallest"
                                    text={moreLikeThisText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledProductCard = styled(ProductCard)((props) => {
    return {
        position: 'relative',
        ['.wrapper']: {
            display: 'block',
            borderRadius: theme.space.small,
            background: theme.color.white,
            cursor: 'pointer',
            // boxShadow: theme.boxShadow,
            height: '100%',
            ['&:hover']: {
                boxShadow: theme.boxShadow
            }
        },
        ['> *']: {
            textDecoration: 'none'
        },
        ['.asosVendorLabel .vendorLabel']: {
            textTransform: 'uppercase'
        },
        boxSizing: 'border-box',
        marginBottom: theme.space.larger,
        ['.content']: {
            padding: theme.space.medium,
            boxSizing: 'border-box',
            borderTop: `1px solid ${theme.color.grayLighter}`,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            ['& .contentRow']: {
                minWidth: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                boxSizing: 'border-box',
                marginBottom: theme.space.smallest,
                ['& > *']: {
                    alignSelf: 'center'
                },
                ['& button']: {
                    padding: 0
                }
            },
            ['& > *.contentRowVendor']: {
                justifyContent: 'flex-start'
            }
        },
        ['.contentRowGroup']: {
            marginBottom: theme.space.smaller,
            width: '100%'
        },
        ['.contentRowGroup:last-of-type']: {
            marginBottom: 0
        },
        ['.contentRow:last-of-type']: {
            marginBottom: 0
        },
        ['.image']: {
            borderRadius: `${theme.space.small} ${theme.space.small} 0 0`
        },
        ['.originalPrice']: {
            marginRight: theme.space.smaller
        },
        justifyContent: 'center',
        ['.badge']: {
            display: 'inline-block',
            padding: `${theme.space.smallest} ${theme.space.smaller}`,
            marginRight: theme.space.smallest,
            marginTop: theme.space.smallest,
            background: `rgba(255, 255, 255, .85)`
        },
        ['.discountBadge']: {
            background: '#c90000'
        },
        ['.deliveryIcon']: {
            marginRight: theme.space.smaller,
            verticalAlign: 'middle'
        },
        ['.saveButtonContainer']: { position: 'relative' },
        ['.saveItem-tooltip']: {
            position: 'absolute',
            transform: 'translate(-50%, -100%) ',
            zIndex: theme.layer.overlay,
            width: '186px',
            pointerEvents: 'all',
            ['& .close-tooltip']: {
                marginTop: theme.space.medium,
                textDecoration: 'underline',
                fontWeight: 'bold'
            }
        },
        ['.saveButton']: {
            border: `0.5px solid ${theme.color.grayLighter}`
        },
        ['.image-wrapper']: {
            position: 'relative',
            height: mobileHeight,
            [`${theme.media.largeScreenBreakpoint}`]: {
                height: desktopHeight
            }
        },
        ['.image-link']: {
            display: 'flex'
        },
        ['.labels']: {
            position: 'absolute',
            bottom: theme.space.medium,
            left: 0,
            right: theme.space.medium,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
        }
    }
})
export default withRouter(StyledProductCard)
