import axios from 'axios'
import axiosRetry from 'axios-retry'
import ReactGA from 'react-ga'

const API_KEY = 'AIzaSyCV8JzIypjF2FtVTCTs4JmGZT4eisg0xkA'
const API_URL = 'https://api.lykdat.com'
const MERCHANDISER_ENDPOINT = 'https://merchandizr.lykdat.com'
// const MERCHANDISER_ENDPOINT = 'http://localhost:3020'
interface RequestHeaders {
    'Content-Type'?: string | null
    'X-Auth-Token'?: string | null
    'Country-Code'?: string | null
}

export function getHeaders(options: {
    token?: string | null
    contentType?: string | null
    countryCode?: string | null
}): { headers: RequestHeaders } {
    const headers = {}

    if (options.contentType) {
        headers['Content-Type'] = options.contentType
    }
    if (options.token) {
        headers['X-Auth-Token'] = `Bearer ${options.token}`
    }
    if (options.countryCode) {
        headers['Country-Code'] = options.countryCode
    }

    return { headers }
}

export function apiURL(path: string, query?: string): string {
    const maybeSlash = path[0] === '/' ? '' : '/'
    const querySuffix = query ? `&${query}` : ''
    return `${API_URL}${maybeSlash}${path}?api_key=${API_KEY}${querySuffix}`
}

export interface LikedProductsResponse {
    saved_products: Product[]
}

export function getLikedProducts(
    token: string
): Promise<LikedProductsResponse> {
    return new Promise((resolve, reject) => {
        axios
            .get(
                apiURL('/user/favorite', `t=${Date.now()}`),
                getHeaders({ token })
            )
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function saveLikedProduct(product: Product, token: string) {
    return new Promise<void>((resolve, reject) => {
        const body = { op: 'save_item', product_id: product.id }
        axios
            .patch(apiURL('/user/favorite'), body, getHeaders({ token }))
            .then((res) => resolve())
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function unsaveLikedProduct(productId: string, token: string) {
    return new Promise<void>((resolve, reject) => {
        const body = { op: 'unsave_item', product_id: productId }
        axios
            .patch(apiURL('/user/favorite'), body, getHeaders({ token }))
            .then((res) => resolve())
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export interface RecentItemsResponse {
    recent_searches: RecentSearch[]
    recent_products: Array<{ product: Product }>
}

export function getRecentItems(token: string) {
    return new Promise<RecentItemsResponse>((resolve, reject) => {
        axios
            .get(apiURL('/user/recent'), getHeaders({ token }))
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                console.log(err)
                reject(err)
            })
    })
}

export function saveRecentSearch(imageUrl: string, token: string) {
    return new Promise<void>((resolve, reject) => {
        axios
            .patch(
                apiURL('/user/recent'),
                {
                    recent_search: imageUrl,
                    op: 'save_search'
                },
                getHeaders({ token })
            )
            .then((res) => resolve())
            .catch((err) => {
                console.log(err)
                reject(err)
            })
    })
}

export function saveRecentProduct(product: Product, token: string) {
    return new Promise<void>((resolve, reject) => {
        axios
            .patch(
                apiURL('/user/recent'),
                {
                    recent_product: product.id,
                    op: 'save_recent_product'
                },
                getHeaders({ token })
            )
            .then((res) => resolve())
            .catch((err) => {
                console.log(err)
                reject(err)
            })
    })
}

export function textSearch(
    query: string,
    countryCode?: string | null
): Promise<TextSearchResponse> {
    return new Promise((resolve, reject) => {
        axios
            .get(
                apiURL('/text-search', `query=${encodeURIComponent(query)}`),
                getHeaders({ countryCode })
            )
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function getPromos(): Promise<PromoData> {
    return new Promise((resolve, reject) => {
        axios
            .get(apiURL('/promo'))
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function getCurrencyRates(): Promise<CurrencyRates> {
    return new Promise((resolve, reject) => {
        axios
            .get(apiURL('/currency-rates'))
            .then((res) => {
                const val: CurrencyRates = res.data
                val.rates[val.base] = 1.0
                resolve(val)
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function getTrendingProducts(): Promise<Product[]>
export function getTrendingProducts(
    gender?: string,
    skip?: number,
    // tslint:disable-next-line:unified-signatures
    limit?: number
): Promise<Product[]>

export function getTrendingProducts(
    gender?: string,
    skip?: number,
    limit?: number
): Promise<Product[]> {
    return new Promise((resolve, reject) => {
        // the limit is 5000 products, but we fetch from
        // top 3000 to ensure we are only displaying products
        // up the ladder
        const offset = skip || Math.floor(Math.random() * 3000) + 1
        axios
            .get(
                `${MERCHANDISER_ENDPOINT}/recommendation/product?limit=${limit ||
                    20}&skip=${offset}&gender=${gender}`
            )
            .then((res) => {
                resolve(res.data.data)
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

interface ImageURLParam {
    imageURL: string
    boundingBox?: BoundingBox
    vendor?: string | null
}

interface ImageBlobParam {
    image: Blob
    boundingBox?: BoundingBox
    vendor?: string | null
}

type SearchRequest = ImageBlobParam | ImageURLParam

export function search(req: SearchRequest, ctx: AppContextInterface) {
    return new Promise<string>((resolve, reject) => {
        const queryParams = new URLSearchParams(location.search)
        const vendor = queryParams.get('vendor')
        if (vendor) {
            req.vendor = vendor
        }

        const formData = createSearchFormData(req)

        ctx.setLoader({
            show: true,
            textList: [
                'Uploading image...',
                'Analyzing image...',
                'Finding similar fashion items...'
            ]
        })
        ReactGA.event({
            category: 'User',
            action: `Attempted search`
        })

        axiosRetry(axios, {
            retries: 3,
            retryCondition: (err) => !err.response
        })
        axios
            .post(
                apiURL('/search'),
                formData,
                getHeaders({
                    contentType: 'multipart/form-data',
                    countryCode: ctx.userCountry
                })
            )
            .then((response) => {
                // todo: a lot of this mess shouldn't be happening here
                const searchResponse: SearchResultData = response.data
                const query = searchResponse.public_url
                if (
                    !ctx.lastSearchResult ||
                    ctx.lastSearchResult.public_url !== query
                ) {
                    ctx.storage.saveRecentSearch(searchResponse, ctx)
                }
                ctx.setLastSearchResult(searchResponse)
                const encodedImgUrl = encodeURIComponent(query)
                const vendorQuery = vendor ? `&vendor=${vendor}` : ''
                const searchResult = `/search-result/?image_url=${encodedImgUrl}${vendorQuery}`
                ctx.setLoader({ show: false, text: '' })
                resolve(searchResult)
                searchResponse.results.forEach((result) => {
                    ReactGA.event({
                        category: 'App',
                        action: `Search result max score`,
                        value: Math.round(result.max_score * 10)
                    })
                })
            })
            .catch((err) => {
                ctx.setLoader({ show: false, text: '' })
                ctx.setToastMessage({
                    text: 'Search for similar items failed :(',
                    type: 'error',
                    timeout: 7000
                })
                ReactGA.event({
                    category: 'App',
                    action: `Search ended in error: ${err}`
                })
                reject(err)
            })
    })
}

function createSearchFormData(req: SearchRequest): FormData {
    const formData = new FormData()
    if ((req as ImageBlobParam).image) {
        formData.append('image', (req as ImageBlobParam).image, 'image.jpeg')
    } else {
        formData.append('image_url', (req as ImageURLParam).imageURL)
    }

    if (req.boundingBox) {
        formData.append('top', req.boundingBox.top.toString())
        formData.append('bottom', req.boundingBox.bottom.toString())
        formData.append('right', req.boundingBox.right.toString())
        formData.append('left', req.boundingBox.left.toString())
    }

    if (req.vendor) {
        formData.append('vendor', req.vendor)
    }

    return formData
}

interface ExploreItemsResponse {
    generic_explore: ExploreItemData[] | null
    user_explore: ExploreItemData[] | null
}
export function getExploreItems(
    token?: string | null,
    countryCode?: string | null,
    allowGeneric?: boolean,
    sourceImageURLs?: string[]
): Promise<ExploreItemData[]> {
    return new Promise((resolve, reject) => {
        let urlQuery = sourceImageURLs ? `image_urls=${sourceImageURLs.join(',')}` : ''
        if (allowGeneric) {
            urlQuery = urlQuery ? `allow_generic=1&${urlQuery}` : 'allow_generic=1'
        }
        const url = apiURL('/explore', urlQuery)
        axios
            .get(url, getHeaders({ token, countryCode }))
            .then((res) => {
                const data = res.data as ExploreItemsResponse
                const items = data.user_explore && data.user_explore.length ? data.user_explore : data.generic_explore
                resolve(items || [])
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })
}

export function trackMerchandiserEvent(d: BMEvent): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.post(`${MERCHANDISER_ENDPOINT}/events`, d).catch((e) => {
            console.log(e)
            reject(e)
        })
    })
}

export async function getShopProducts(q: string): Promise<any[]> {
    try {
        const response = await axios.get(
            `${MERCHANDISER_ENDPOINT}/recommendation/product?${q}`
        )
        return response.data.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getTopSellers(): Promise<string[]> {
    try {
        const response = await axios.get(
            `${MERCHANDISER_ENDPOINT}/recommendation/vendor?limit=20`
        )
        return response.data.data.map((item: { id: string }) => item.id)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getTopSubCategories(): Promise<string[]> {
    try {
        const response = await axios.get(
            `${MERCHANDISER_ENDPOINT}/recommendation/sub_category`
        )
        return response.data.data.map((item: { id: string }) => item.id)
    } catch (error) {
        console.log(error)
        throw error
    }
}
