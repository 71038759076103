import React, { useRef, useContext } from 'react'
import AppContext from '../../helpers/AppContext'
import { PrimaryCta } from '../Button'
import { Props as ButtonProps } from '../Button/Button'
import { useTranslate } from '../../helpers/useTranslate'
import * as helper from './helper'

export interface Props extends ButtonProps {
    onImageAdded: (src: string) => void
    children?: React.ReactElement
}

const getCta = (props: Props, fallbackText: string, onClick: () => void) => {
    if (props.children) {
        return <div onClick={onClick}>{props.children}</div>
    }

    return <PrimaryCta
        onClick={onClick}
        icon="Camera"
        text={props.text || fallbackText}
        {...props}
    />
}

const ImagePicker: React.FC<Props> = (props) => {
    const { onImageAdded } = props
    const ctx = useContext(AppContext)
    const fileField: React.MutableRefObject<HTMLInputElement | null> = useRef(
        null
    )
    const onClick = () => helper.onClick(fileField.current)
    const cta = getCta(props, 'Select Picture', onClick)

    return (
        <>
            <input
                hidden={true}
                ref={fileField}
                type="file"
                accept="image/*"
                onChange={(e) => helper.onFileAdded(e.target.files, ctx, onImageAdded)}
            />
            {cta}
        </>
    )
}

export default ImagePicker
