import axios from 'axios'

const API_URL = ' https://bacillus-api-styler-vs6o6hcumq-uc.a.run.app/v1'
const BG_API_URL = 'https://bacillus-api-img-p-vs6o6hcumq-uc.a.run.app/v1'
const DEEP_TAG_API_URL = 'https://bacillus-api-detect-colors-vs6o6hcumq-uc.a.run.app/v1/detection/tags-v2'
const API_KEY = '28c56bc19e8010689b16f8170bc88eda926de4d3fc76516c34a89b3551df9a87'

interface DetectedItem {
    name: string
    category: string | null
    detection_confidence: number
}

export async function detectItems(img: Blob): Promise<DetectedItem[]> {
    const formData = new FormData()
    formData.append('image', img, 'image.jpeg')

    const url = `${API_URL}/detection/items`
    const headers = {'x-api-key': API_KEY}
    const resp = await axios.post(url, formData, { headers })

    return resp.data.data.detected_items
}

interface DetectedColor {
    name: string
    score: number
    code?: string
}

export async function detectColors(img: Blob): Promise<DetectedColor[]> {
    const formData = new FormData()
    formData.append('image', img, 'image.jpeg')

    const url = `${API_URL}/detection/tags`
    const headers = {'x-api-key': API_KEY}
    const resp = await axios.post(url, formData, { headers })

    return resp.data.data.colors
}

export interface StyleResponse {
    groups: {
        styles: {
            image: string
        }[]
    }[]
}

export async function getStyles(img: Blob, gender: string): Promise<StyleResponse> {
    const formData = new FormData()
    formData.append('image', img, 'image.jpeg')
    formData.append('gender', gender)

    const url = `${API_URL}/experiments/styleme`
    const resp = await axios.post(url, formData)

    return resp.data.data
}

export interface ImageTranscodeResponse {
    image: string
}

export async function removeImageBackground(img: Blob): Promise<ImageTranscodeResponse> {
    const formData = new FormData()
    formData.append('image', img, 'image.jpeg')

    const headers = {'x-api-key': API_KEY}
    const url = `${BG_API_URL}/images/transcode`
    const resp = await axios.post(url, formData, { headers })

    return resp.data.data
}

export interface ImageTaggingResponse {
    colors: DetectedColor[]
    keywords: {
        classification: string
        name: string
        score: number
    }[]
}

export async function getTagging(img: Blob): Promise<ImageTaggingResponse> {
    const formData = new FormData()
    formData.append('image', img, 'image.jpeg')

    const headers = {'x-api-key': API_KEY}
    const resp = await axios.post(DEEP_TAG_API_URL, formData, { headers })

    return resp.data.data
}
