import React from 'react'
import ImageDeepTagging from '../../components/ImageDeepTagging'

const BgRemover: React.FC = () => {
    return (
        <>
            <ImageDeepTagging />
        </>
    )
}

export default BgRemover
